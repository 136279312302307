import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectShowImgDialog1, setShowImgDialog1 } from '../state/slices';
import FormDetails from "../../src/models/FormDetails";
import { isValidString, getUrlParams } from "../../src/utils/Utilities";
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress from Material-UI

function ImgDialog1() {
    const dispatch = useDispatch();
    const showImgDialog1 = useSelector(selectShowImgDialog1);

    // Local state to handle animation trigger and form data
    const [isVisible, setIsVisible] = useState(false);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false); // Loading state
    const [error, setError] = useState(null); // Error state

    const params = getUrlParams();

    // Trigger the dialog animation after 5 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);  // Start fade-in animation after 5 seconds
        }, 5000);

        return () => clearTimeout(timer); // Cleanup on component unmount
    }, []);

    // Function to get styles based on screen size
    const getDialogStyles = () => {
        const width = window.innerWidth;

        if (width >= 1024) {
            // Desktop styles
            return {
                width: '430px',
                padding: '20px',
                borderRadius: '10px',
                backgroundColor: 'white',
                overflow: 'auto', // Enable scrolling
                maxHeight: '95vh', // Set max height for content
                scrollbarWidth: 'none', // For Firefox
                msOverflowStyle: 'none', // For Internet Explorer and Edge
                '&::-webkit-scrollbar': {
                    display: 'none', // For Chrome, Safari, and newer versions of Edge
                },
            };
        } else if (width >= 768) {
            // Tablet styles
            return {
                width: '80vw',
                maxWidth: '425px',
                padding: '18px',
                borderRadius: '10px',
                backgroundColor: 'white',
                overflow: 'auto', // Enable scrolling
                maxHeight: '95vh', // Set max height for content
                scrollbarWidth: 'none', // For Firefox
                msOverflowStyle: 'none', // For Internet Explorer and Edge
                '&::-webkit-scrollbar': {
                    display: 'none', // For Chrome, Safari, and newer versions of Edge
                },
            };
        } else {
            // Mobile styles
            return {
                width: '90vw',
                maxWidth: '420px',
                padding: '15px',
                borderRadius: '10px',
                backgroundColor: 'white',
                overflow: 'auto', // Enable scrolling
                maxHeight: '80vh', // Set max height for content
                scrollbarWidth: 'none', // For Firefox
                msOverflowStyle: 'none', // For Internet Explorer and Edge
                '&::-webkit-scrollbar': {
                    display: 'none', // For Chrome, Safari, and newer versions of Edge
                },
            };
        }
    };

    const dialogStyles = getDialogStyles();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Set loading to true when submission starts
        setError(null); // Reset error

        const formDetails = new FormDetails({
            firstName: name,
            lastName: 'n/a',
            phone: phone,
            email: email,
            gender: 'n/a',
            city: 'n/a',
            message: 'offer dialog',
            campaignName: params.utmCampaign,
            adgroupName: params.utmAdGroupName,
            keyword: params.utmTerm
        });

        const validationCheck = formDetails.allFieldsAreValid();
        if (validationCheck.isValid) {
            try {
                const response = await fetch("https://script.google.com/macros/s/AKfycbw5UXFE8GTRWAQDlGMt3bFjloYN2r_X2gWalv0f2I6DRqMJD-_5nAyEwMzT8Aa1n58j/exec", {
                    method: "POST",
                    body: formDetails.toJSON(),
                });
                if (response.ok) {
                    // Handle successful response
                    console.log('Form submitted successfully');
                    dispatch(setShowImgDialog1(false));
                } else {
                    // Handle server errors
                    setError('Failed to submit form. Please try again later.');
                }
            } catch (error) {
                // Handle network or other errors
                setError('An error occurred. Please try again later.');
            }
        } else {
            // Handle validation errors
            setError('Please fill in all required fields correctly.');
        } 
            setLoading(false); // Set loading to false once submission is complete
        
    };

    return (
        <>
            {showImgDialog1 && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        opacity: isVisible ? 1 : 0,
                        transition: 'opacity 0.5s ease',
                        zIndex: 1000,
                        pointerEvents: isVisible ? 'auto' : 'none',
                    }}
                    onClick={() => {
                        // dispatch(setShowImgDialog1(false));
                    }}
                >
                    <div
                        style={{
                            position: 'relative',
                            ...dialogStyles,
                        }}
                        onClick={(e) => e.stopPropagation()} // Prevent closing on content click
                    >
                        <span
                            style={{
                                position: 'absolute',
                                top: '10px',
                                right: '15px',
                                fontSize: '24px',
                                fontWeight: 'bold',
                                color: 'black',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                dispatch(setShowImgDialog1(false));
                            }}
                        >&times;</span>

                        {/* Add informational text above the form */}
                        <p style={{
                            textAlign: 'center',
                            marginTop: '0px',
                            fontSize: '16px',
                            color: '#333',
                            fontWeight: 'bold',
                        }}>
                            Fill the form to avail the offer
                        </p>

                        <img
                            src="/img-dialog1.png"
                            alt="Popup"
                            style={{
                                width: '100%',    // Ensure image takes full width of container
                                height: 'auto',   // Maintain aspect ratio based on width
                                maxHeight: '520px', // Set a fixed max height (adjust as necessary)
                                objectFit: 'contain', // Ensure the image is contained and not cropped
                            }}
                        />

                        {/* Form fields below the text */}
                        <form onSubmit={handleSubmit} style={{ marginTop: '10px' }}>
                            <label style={{ display: 'block', marginBottom: '10px' }}>
                                <input
                                    type="text"
                                    placeholder="Name"
                                    required
                                    style={{
                                        width: '100%',
                                        padding: '10px',
                                        boxSizing: 'border-box',
                                    }}
                                    onChange={(event) => {
                                        setName(event.target.value);
                                    }}
                                />
                            </label>

                            <label style={{ display: 'block', marginBottom: '10px' }}>
                                <input
                                    type="text"
                                    placeholder="Phone"
                                    required
                                    style={{
                                        width: '100%',
                                        padding: '10px',
                                        boxSizing: 'border-box',
                                    }}
                                    onChange={(event) => {
                                        setPhone(event.target.value);
                                    }}
                                />
                            </label>

                            <label style={{ display: 'block', marginBottom: '10px' }}>
                                <input
                                    type="email"
                                    placeholder="Email"
                                    required
                                    style={{
                                        width: '100%',
                                        padding: '10px',
                                        boxSizing: 'border-box',
                                    }}
                                    onChange={(event) => {
                                        setEmail(event.target.value);
                                    }}
                                />
                            </label>

                            {/* Submit button */}
                            <button
                                type="submit"
                                style={{
                                    width: '100%',
                                    padding: '12px',
                                    backgroundColor: '#023273',
                                    color: 'white',
                                    border: 'none',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                    marginTop: '5px',
                                }}
                                disabled={loading} // Disable button while loading
                            >
                                {loading ? 'Submitting...' : 'Submit'}
                            </button>

                            {/* Display error message */}
                            {error && (
                                <p style={{
                                    color: 'red',
                                    textAlign: 'center',
                                    marginTop: '10px'
                                }}>
                                    {error}
                                </p>
                            )}
                        </form>

                        {/* Loading overlay */}
                        {loading && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent white background
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    zIndex: 1100, // Ensure it's above the dialog content
                                }}
                            >
                                <CircularProgress />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}

export default ImgDialog1;
