import React from 'react';
import '../../styles/SmpForAlopeciaPage/TreatmentsForAlopecia.css';
import { SMP_FOR_ALOPECIA_URL_1, SMP_FOR_ALOPECIA_URL_2 } from '../../constants';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { display } from '@mui/system';


function TreatmentsForAlopecia() {
    const { width } = useWindowDimensions();
    const isDesktop = width >= 1025;
    const isTablet = width >= 768 && width <= 1024;
    const isMobile = width <= 767;

    function reValue({ desktop, tablet, mobile }) {
        if (isDesktop) {
            return desktop;
        }
        if (isTablet) {
            return tablet;
        }
        if (isMobile) {
            return mobile;
        }
    }

    return (
        <div style={reValue({
            desktop: { display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'center' },
            tablet: { display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'center' },
            mobile: { display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'center' },
        })}>
            <div style={reValue({
                desktop: { width: '90%' },
                tablet: { width: '80%' },
                mobile: { width: '90%' },
            })}>
                <div style={reValue({
                    desktop: { float: 'right', margin: '0px 0px 0px 20px' },
                    tablet: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' },
                    mobile: { display: 'flex', flexDirection: 'column' }
                })}>
                    <img style={reValue({
                        desktop: { height: '330px', width: '250px', margin: '0px 20px 0px 0px' },
                        tablet: { height: '50vw', width: '48.5%', margin: '0px 0px 0px 0px' },
                        mobile: { height: '100vw', width: '100%', margin: '0px 0px 0px 0px' },
                    })}
                        src={SMP_FOR_ALOPECIA_URL_1} alt="Scalp Micropigmentation"
                    />
                    <img style={reValue({
                        desktop: { height: '330px', width: '250px', margin: '0px 0px 0px 10px' },
                        tablet: { height: '50vw', width: '48.5%', margin: '0px 0px 0px 0px' },
                        mobile: { height: '100vw', width: '100%', margin: '20px 0px 0px 0px' },
                    })}
                        src={SMP_FOR_ALOPECIA_URL_2} alt="Scalp Micropigmentation"
                    />
                </div>
                <div>
                    <p style={reValue({
                        desktop: { textAlign: 'justify', fontSize: '15px', margin: '0px 0px 0px 0px', lineHeight: '25px' },
                        tablet: { textAlign: 'justify', fontSize: '15px', margin: '40px 0px 0px 0px', lineHeight: '25px' },
                        mobile: { textAlign: 'justify', fontSize: '15px', margin: '20px 0px 0px 0px', lineHeight: '25px' },
                    })}>
                        {/* Alopecia serves as a broad term encompassing various types of hair loss, each of which scalp micropigmentation can assist in by creating the illusion of hair follicles. Among the different types, alopecia areata stands as the most prevalent, characterized by non-scarring hair loss typically beginning as a small, coin-sized patch. The size of the patch may fluctuate over time, with unpredictable rates of hair regrowth or loss. Scalp micropigmentation aids in concealing these patches by bridging the gap between the existing hair and the bare scalp, effectively camouflaging the alopecia-affected areas. On the other hand, alopecia totalis represents a more severe manifestation, resulting in complete hair loss across the scalp. In such cases, scalp micropigmentation offers the option of creating a natural-looking hairline and closely shaved appearance through the treatment process. */}
                        Alopecia is a general term for different types of hair loss, and scalp micropigmentation (SMP) can help by creating the appearance of hair follicles. The most common type is alopecia areata, which starts with small, coin-sized patches of hair loss on the scalp. These patches can change in size over time, with hair sometimes growing back or falling out unpredictably. If alopecia areata worsens, it can lead to alopecia totalis, where all the hair on the scalp is lost. In even more severe cases, it can progress to alopecia universalis, which causes total hair loss across the entire body, including the scalp. SMP helps by filling in the gaps between existing hair and bare spots, making these patches less noticeable. For those with alopecia totalis, SMP can create the look of a natural hairline and a closely shaved head, offering a realistic alternative to a completely bald scalp. The number of SMP sessions required to effectively conceal an alopecia patch depends on the rate of hair loss or regrowth. Typically, we recommend three to four sessions for achieving dense coverage. Additionally, it's important to note that you may experience other patches of hair loss on your scalp that you may wish to address with SMP.
                    </p>
                    <p style={{ fontSize: '28px', fontWeight: 'bold' }}>
                        In what ways can SMP help you?
                    </p>
                    <p style={{ textAlign: 'justify', fontSize: '15px', margin: '0px 0px 0px 0px', lineHeight: '25px' }}>
                        Alopecia universalis affects larger areas, including the entire body and scalp. As mentioned above, SMP can help create the appearance of a closely shaved scalp and define a hairline for individuals dealing with universalis or totalis alopecia. The number of SMP sessions required to effectively conceal an alopecia patch depends on the rate of hair loss or regrowth. Typically, we recommend three sessions for achieving dense coverage. Additionally, it's important to note that you may experience other patches of hair loss on your scalp that you may wish to address with SMP.
                    </p>
                    <button className="quoteButtonHomeAlopecia" onClick={() => {
                        window.scrollTo(0, 2000);
                    }}>
                        GET A FREE CONSULTATION
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TreatmentsForAlopecia;
