import { React } from "react";
import "../../styles/HomePage/ConsultationForm.css";
import { CF_HEADING_1, CF_HEADING_2, CF_HEADING_3, CF_RIGHT_BULLET_NUMBER_1, CF_RIGHT_BULLET_NUMBER_1_TEXT, CF_RIGHT_BULLET_NUMBER_2, CF_RIGHT_BULLET_NUMBER_2_TEXT, CF_RIGHT_BULLET_NUMBER_3, CF_RIGHT_BULLET_NUMBER_3_TEXT, CF_RIGHT_BULLET_NUMBER_4, CF_RIGHT_BULLET_NUMBER_4_TEXT } from "../../constants";
import CustomDropdown from "../CustomDropdown";
import { useSelector, useDispatch } from 'react-redux';
import { selectGender, setGender } from '../../state/slices';
import { selectService, setService } from '../../state/slices';
import { setShowDialog } from '../../state/slices';
import { setShowLoading } from '../../state/slices'
import { selectFirstName, setFirstName } from '../../state/slices';
import { selectLastName, setLastName } from '../../state/slices';
import { selectPhone, setPhone } from '../../state/slices';
import { selectEmail, setEmail } from '../../state/slices';
import { selectMessage, setMessage } from '../../state/slices';
import { selectCity, setCity } from '../../state/slices';
import { selectDialogTitle, setDialogTitle } from '../../state/slices'
import { selectDialogDesc, setDialogDesc } from '../../state/slices'
import { isValidString , getUrlParams} from "../../utils/Utilities";
import FormDetails from "../../models/FormDetails";
import { useNavigate } from "react-router-dom";


function ConsultationForm() {
    return (
        <div className="consultation-form-main-column">
            {/* <div className='consult-text-1'>
                {CF_HEADING_1}
            </div> */}
            <div className='consult-text-2'>
                {CF_HEADING_2}
            </div>
            {/* <div className='consult-text-3'>
                {CF_HEADING_3}
            </div> */}
            <div className="consultation-card">
                <div className="in-card-row">
                    <LeftSection></LeftSection>
                    <RightSection></RightSection>
                </div>
            </div>
        </div>
    );
}

function LeftSection() {
    return (<div className="form-column">
        <form className="the-form">
            <FirstRow></FirstRow>
            <SecondRow></SecondRow>
            <ThirdRow></ThirdRow>
            <FourthRow></FourthRow>
            <FifthRow></FifthRow>
        </form>
    </div>
    );
}

function FirstRow() {
    const firstName = useSelector(selectFirstName);
    const lastName = useSelector(selectLastName);
    const dispatch = useDispatch();
    return (
        <div className="input-row">
            <div className="input-row-inner-column-1">
                <label className="input-label">First name*</label>
                <input
                    className="text-field-input"
                    type="text"
                    id="fname"
                    name="firstname"
                    placeholder=""
                    value={firstName}
                    onChange={(event) => {
                        dispatch(setFirstName(event.target.value))
                    }}
                ></input>
            </div>
            <div className="input-row-inner-column-2">
                <label className="input-label">Last name*</label>
                <input
                    className="text-field-input"
                    type="text"
                    id="lname"
                    name="lastname"
                    placeholder=""
                    value={lastName}
                    onChange={(event) => {
                        dispatch(setLastName(event.target.value))
                    }}
                ></input>
            </div>
        </div>
    );
}

function SecondRow() {
    const phone = useSelector(selectPhone);
    const email = useSelector(selectEmail);
    const dispatch = useDispatch();
    return (
        <div className="input-row">
            <div className="input-row-inner-column-1">
                <label className="input-label">Email*</label>
                <input
                    className="text-field-input"
                    type="text"
                    id="email"
                    name="email"
                    placeholder=""
                    value={email}
                    onChange={(event) => {
                        dispatch(setEmail(event.target.value))
                    }}
                ></input>
            </div>
            <div className="input-row-inner-column-2">
                <label className="input-label">Phone*</label>
                <input
                    className="text-field-input"
                    type="text"
                    id="phone"
                    name="phone"
                    placeholder=""
                    value={phone}
                    onChange={(event) => {
                        dispatch(setPhone(event.target.value))
                    }}
                ></input>
            </div>
        </div>
    );
}

function ThirdRow() {
    const dispatch = useDispatch();
    const city = useSelector(selectCity);
    // function toggleName() {
    //     dispatch(setName(name === 'Tom' ? 'Nick' : 'Tom'));
    // }
    return (
        <div className="input-row">
            <div className="input-row-inner-column-1">
                <label className="input-label">Gender*</label>
                <CustomDropdown
                    options={[
                        { value: '--select--', label: '--select--' },
                        { value: 'Male', label: 'Male' },
                        { value: 'Female', label: 'Female' },
                        { value: 'Other', label: 'Other' },
                    ]}
                    onChange={(value) => {
                        // console.log(`selected gender value abc = ${value}`);
                        dispatch(setGender(value));
                    }}
                ></CustomDropdown>
            </div>
            {/* <div className="input-row-inner-column-2">
                <label className="input-label">Desired Service*</label>
                <CustomDropdown
                    options={[
                        { value: 'SMP', label: 'SMP' },
                        { value: 'Alopecia', label: 'Alopecia' },
                        { value: 'Scars', label: 'Scars' },
                    ]}
                    onChange={(value) => {
                        // console.log(`selected gender value abc = ${value}`);
                        dispatch(setService(value));
                    }}
                ></CustomDropdown>
            </div> */}
            <div className="input-row-inner-column-2">
                <label className="input-label">City</label>
                <input
                    className="text-field-input"
                    type="text"
                    id="city"
                    name="city"
                    placeholder=""
                    value={city}
                    onChange={(event) => {
                        dispatch(setCity(event.target.value))
                    }}
                ></input>
            </div>
        </div>
    );
}

function FourthRow() {
    const message = useSelector(selectMessage);
    const dispatch = useDispatch();
    return (
        <div className="input-row-inner-column">
            <label className="input-label">Message</label>
            <textarea
                className="text-field-subject"
                name="subject"
                placeholder=""
                value={message}
                onChange={(event) => {
                    dispatch(setMessage(event.target.value))
                }}
            >	</textarea>
        </div>
    );
}

function FifthRow() {
    const firstName = useSelector(selectFirstName);
    const lastName = useSelector(selectLastName);
    const phone = useSelector(selectPhone);
    const email = useSelector(selectEmail);
    const gender = useSelector(selectGender);
    const city = useSelector(selectCity);
    const message = useSelector(selectMessage);
    const navigate = useNavigate();

    // const showDialog = useSelector(selectShowDialog);
    const dispatch = useDispatch();

    //
    const params = getUrlParams();
    // console.log('UTM Source:', params.utmSource);
    // console.log('Campaign ID:', params.campaignId);
    
    return (
        <div className="submit-container">
            <button className="submit-button" type="submit" onClick={async (event) => {
                event.preventDefault();
                console.log(`hello smp inc first name = ${firstName}`);
                console.log(`hello smp inc last name = ${lastName}`);
                console.log(`hello smp inc phone = ${phone}`);
                console.log(`hello smp inc email = ${email}`);
                console.log(`hello smp inc gender = ${gender}`);
                console.log(`hello smp inc city = ${city}`);
                console.log(`hello smp inc message = ${message}`);
                const formDetails = new FormDetails({
                    firstName: firstName,
                    lastName: lastName,
                    phone: phone,
                    email: email,
                    gender: gender,
                    city: city,
                    message: message,
                    campaignName: params.utmCampaign,
                    adgroupName: params.utmAdGroupName,
                    keyword: params.utmTerm
                });
                const validationCheck = formDetails.allFieldsAreValid();
                dispatch(setShowLoading(true));
                if (validationCheck.isValid) {
                    const response = await fetch("https://script.google.com/macros/s/AKfycbw5UXFE8GTRWAQDlGMt3bFjloYN2r_X2gWalv0f2I6DRqMJD-_5nAyEwMzT8Aa1n58j/exec",
                        {
                            method: "POST",
                            body: formDetails.toJSON(),
                        }
                    );
                    if (response.ok) {
                        // setTimeout(() => {
                        // dispatch(setDialogTitle("Success"));
                        // dispatch(setDialogDesc("Form submitted!"));
                        dispatch(setShowLoading(false));
                        // dispatch(setShowDialog(true));
                        // }, 3000);
                        window.scrollTo(0, 0);
                        navigate('/thankyoupage/');
                    } else {
                        // setTimeout(() => {
                        dispatch(setDialogTitle("Failed"));
                        dispatch(setDialogDesc("Form wasn't submitted!"));
                        dispatch(setShowLoading(false));
                        dispatch(setShowDialog(true));
                        // }, 3000);
                    }
                } else {
                    dispatch(setDialogTitle("Error"));
                    dispatch(setDialogDesc(validationCheck.error));
                    dispatch(setShowLoading(false));
                    dispatch(setShowDialog(true));
                }
            }}> Submit </button>
        </div>
    );
}

function RightSection() {
    return (
        <div className='consultation-types-section'>
            <div className='consultation-types-column'>
                <div className='consultation-types-title'>
                    Types of Consultations
                </div>
                <ConsultationBullet
                    number={CF_RIGHT_BULLET_NUMBER_1}
                    text={CF_RIGHT_BULLET_NUMBER_1_TEXT}
                ></ConsultationBullet>
                <DottedLine></DottedLine>
                <ConsultationBullet
                    number={CF_RIGHT_BULLET_NUMBER_2}
                    text={CF_RIGHT_BULLET_NUMBER_2_TEXT}
                ></ConsultationBullet>
                <DottedLine></DottedLine>
                <ConsultationBullet
                    number={CF_RIGHT_BULLET_NUMBER_3}
                    text={CF_RIGHT_BULLET_NUMBER_3_TEXT}
                ></ConsultationBullet>
                {/* <DottedLine></DottedLine>
                <ConsultationBullet
                    number={CF_RIGHT_BULLET_NUMBER_4}
                    text={CF_RIGHT_BULLET_NUMBER_4_TEXT}
                ></ConsultationBullet> */}
            </div>
        </div>
    );
}

function ConsultationBullet({ number, text }) {
    return (
        <div className='consultation-bullet-row'>
            <div className='consultation-bullet-circle'>
                <div className='consultation-bullet-number'>{number}</div>
            </div>
            <div className='consultation-bullet-text'>
                {text}
            </div>
        </div>
    );
}

function DottedLine() {
    return (
        <div className='dotted-line-row'>
            <div className='dotted-line'></div>
        </div>
    );
}

export default ConsultationForm;