import React, { useRef } from 'react';
import '../../styles/SmpForWomenPage/TreatmentsForWomen.css';
import { LuChevronLeftCircle, LuChevronRightCircle } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { SMP_FOR_WOMEN_CARD1_IMAGE_1, SMP_FOR_WOMEN_CARD1_IMAGE_2, SMP_FOR_WOMEN_CARD2_IMAGE_1, SMP_FOR_WOMEN_CARD2_IMAGE_2, SMP_FOR_WOMEN_CARD3_IMAGE_1, SMP_FOR_WOMEN_CARD3_IMAGE_2 } from '../../constants';

function TreatmentsForWomen() {
    return (
        <div>
            <TreatmentsForWomenMainDesktop></TreatmentsForWomenMainDesktop>
            <TreatmentsForWomenMainTablet></TreatmentsForWomenMainTablet>
            <TreatmentsForWomenMainMobile></TreatmentsForWomenMainMobile>
        </div>
    );
};

function TreatmentsForWomenMainDesktop() {
    const navigate = useNavigate();
    const styles = {
        buttonContainer: {
            // backgroundColor: 'red',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItms: 'center',
            fontSize: '30px',
            margin: '0px 5px 0px 0px'
        },
        icon: {
            cursor: 'pointer',
            color: 'grey',
        },
    };

    const scrollRef = useRef(null);

    const scrollLeft = () => {
        scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    };

    const scrollRight = () => {
        scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    };
    return (
        <div className='treatments-for-women-main-desktop'>
            <div className='treatments-for-women-head'>
                <div className='treatments-for-women-head-column'>
                    <div className='treatments-for-women-text'>
                        HOW IT WORKS
                    </div>
                    <div className='treatments-for-women-text2'>
                        Overcoming Hair Loss is Finally Easy!
                    </div>
                </div>
                <div style={styles.buttonContainer}>
                    <LuChevronLeftCircle onClick={scrollLeft} style={styles.icon} />
                    <div style={{ width: '15px' }}></div>
                    <LuChevronRightCircle onClick={scrollRight} style={styles.icon} />
                </div>
            </div>
            <div ref={scrollRef} className='treatment-for-women-cards'>
                {/* <TreatmentCard
                    text1={"Crew cut"}
                    text2={"Our crew cut treatment at Micro Inc. SMP builds density over three sessions, each lasting a few hours with minimal downtime. Ideal for those with male pattern baldness, this technique creates a natural, shaved head look. We work closely with you to design your desired hairline through detailed consultations, ensuring the best outcome. For optimal results, avoid washing the treated area for 4 days post-session."}
                    text3={"Explore SMP for Women"}
                    image1={SMP_FOR_WOMEN_CARD1_IMAGE_1}
                    image2={SMP_FOR_WOMEN_CARD1_IMAGE_2}
                    onClickAction={() => {
                    }}
                ></TreatmentCard> */}
                <div className='tc-space'></div>
                <TreatmentCard
                    text1={"Density enhancement"}
                    text2={"Many women face hair loss due to hormonal changes, vitamin deficiencies, and excessive hair product use. Instead of hair transplants, they often try expensive, time-consuming remedies that yield temporary results or worsen hair loss. Scalp micropigmentation offers a permanent, minimally invasive solution, creating the appearance of thicker hair and blending with the natural hairline over three subtle sessions."}
                    text3={"Discover SMP for Women"}
                    image1={SMP_FOR_WOMEN_CARD2_IMAGE_1}
                    image2={SMP_FOR_WOMEN_CARD2_IMAGE_2}
                    onClickAction={() => {

                    }}
                ></TreatmentCard>
                <div className='tc-space'></div>
                <TreatmentCard
                    text1={"Thinning hairline"}
                    text2={"Many women style their hair with a parting influenced by trends or preference, but consistent parting can cause traction alopecia. Women who weave their hair or wear head coverings may also experience thinning, especially near the hairline. Scalp micropigmentation (SMP) blends hair with the scalp, reducing the visibility of thinning and enhancing overall density."}
                    text3={"Learn about SMP for Alopecia"}
                    image1={SMP_FOR_WOMEN_CARD3_IMAGE_1}
                    image2={SMP_FOR_WOMEN_CARD3_IMAGE_2}
                    onClickAction={() => {
                    }}
                // addBottomSpace={true}
                ></TreatmentCard>
                {/* <div className='tc-space'></div> */}
                {/* <TreatmentCard
                    text1={"SMP for scars"}
                    text2={"Conceal scars and imperfections with our innovative SMP techniques, seamlessly blending them with your natural hairline. Restore both appearance and confidence with our personalized solutions."}
                    text3={"Explore SMP for Scars"}
                    onClickAction={() => {
                        
                    }}
                ></TreatmentCard> */}
            </div>
        </div>
    );
}

function TreatmentsForWomenMainTablet() {
    const navigate = useNavigate();
    return (
        <div className='treatments-for-women-main-tablet'>
            <div className='treatments-for-women-head'>
                <div className='treatments-for-women-head-column'>
                    <div className='treatments-for-women-text'>
                        TREATMENTS
                    </div>
                    <div className='treatments-for-women-text2'>
                        Overcoming hair loss is finally easy!
                    </div>
                </div>
            </div>
            <div className='treatment-for-women-cards1'>
                {/* <TreatmentCard
                    text1={"Crew cut"}
                    text2={"Our crew cut treatment at Micro Inc. SMP builds density over three sessions, each lasting a few hours with minimal downtime. Ideal for those with male pattern baldness, this technique creates a natural, shaved head look. We work closely with you to design your desired hairline through detailed consultations, ensuring the best outcome. For optimal results, avoid washing the treated area for 4 days post-session."}
                    text3={"Explore SMP for Women"}
                    image1={SMP_FOR_WOMEN_CARD1_IMAGE_1}
                    image2={SMP_FOR_WOMEN_CARD1_IMAGE_2}
                    onClickAction={() => {
                    }}
                ></TreatmentCard> */}
                <TreatmentCard
                    text1={"Density enhancement"}
                    text2={"Many women face hair loss due to hormonal changes, vitamin deficiencies, and excessive hair product use. Instead of hair transplants, they often try expensive, time-consuming remedies that yield temporary results or worsen hair loss. Scalp micropigmentation offers a permanent, minimally invasive solution, creating the appearance of thicker hair and blending with the natural hairline over three subtle sessions."}
                    text3={"Discover SMP for Women"}
                    image1={SMP_FOR_WOMEN_CARD2_IMAGE_1}
                    image2={SMP_FOR_WOMEN_CARD2_IMAGE_2}
                    onClickAction={() => {
                    }}
                ></TreatmentCard>
                <TreatmentCard
                    text1={"Thinning hairline"}
                    text2={"Many women style their hair with a parting influenced by trends or preference, but consistent parting can cause traction alopecia. Women who weave their hair or wear head coverings may also experience thinning, especially near the hairline. Scalp micropigmentation (SMP) blends hair with the scalp, reducing the visibility of thinning and enhancing overall density."}
                    text3={"Learn about SMP for Alopecia"}
                    image1={SMP_FOR_WOMEN_CARD3_IMAGE_1}
                    image2={SMP_FOR_WOMEN_CARD3_IMAGE_2}
                    onClickAction={() => {
                    }}
                    addBottomSpace={true}
                ></TreatmentCard>
            </div>
            <div className='treatment-for-women-cards2'>
                {/* <TreatmentCard
                    text1={"Thinning hairline"}
                    text2={"Many women style their hair with a parting influenced by trends or preference, but consistent parting can cause traction alopecia. Women who weave their hair or wear head coverings may also experience thinning, especially near the hairline. Scalp micropigmentation (SMP) blends hair with the scalp, reducing the visibility of thinning and enhancing overall density."}
                    text3={"Learn about SMP for Alopecia"}
                    image1={SMP_FOR_WOMEN_CARD3_IMAGE_1}
                    image2={SMP_FOR_WOMEN_CARD3_IMAGE_2}
                    onClickAction={() => {
                    }}
                    addBottomSpace={true}
                ></TreatmentCard> */}
                {/* <TreatmentCard
                    text1={"SMP for scars"}
                    text2={"Conceal scars and imperfections with our innovative SMP techniques, seamlessly blending them with your natural hairline. Restore both appearance and confidence with our personalized solutions."}
                    text3={"Explore SMP for Scars"}
                    onClickAction={() => {
                    }}
                ></TreatmentCard> */}
            </div>
        </div>
    );
}

function TreatmentsForWomenMainMobile() {
    const navigate = useNavigate();
    return (
        <div className='treatments-for-women-main-mobile'>
            <div className='treatments-for-women-head'>
                <div className='treatments-for-women-head-column'>
                    <div className='treatments-for-women-text'>
                        TREATMENTS
                    </div>
                    <div className='treatments-for-women-text2'>
                        Overcoming hair loss is finally easy!
                    </div>
                </div>
            </div>
            <div className='treatment-for-women-cards'>
                {/* <TreatmentCard
                    text1={"Crew cut"}
                    text2={"Our crew cut treatment at Micro Inc. SMP builds density over three sessions, each lasting a few hours with minimal downtime. Ideal for those with male pattern baldness, this technique creates a natural, shaved head look. We work closely with you to design your desired hairline through detailed consultations, ensuring the best outcome. For optimal results, avoid washing the treated area for 4 days post-session."}
                    text3={"Explore SMP for Women"}
                    image1={SMP_FOR_WOMEN_CARD1_IMAGE_1}
                    image2={SMP_FOR_WOMEN_CARD1_IMAGE_2}
                    onClickAction={() => {
                    }}
                ></TreatmentCard> */}
                <TreatmentCard
                    text1={"Density enhancement"}
                    text2={"Many women face hair loss due to hormonal changes, vitamin deficiencies, and excessive hair product use. Instead of hair transplants, they often try expensive, time-consuming remedies that yield temporary results or worsen hair loss. Scalp micropigmentation offers a permanent, minimally invasive solution, creating the appearance of thicker hair and blending with the natural hairline over three subtle sessions."}
                    text3={"Discover SMP for Women"}
                    image1={SMP_FOR_WOMEN_CARD2_IMAGE_1}
                    image2={SMP_FOR_WOMEN_CARD2_IMAGE_2}
                    onClickAction={() => {
                    }}
                ></TreatmentCard>
                <TreatmentCard
                    text1={"Thinning hairline"}
                    text2={"Many women style their hair with a parting influenced by trends or preference, but consistent parting can cause traction alopecia. Women who weave their hair or wear head coverings may also experience thinning, especially near the hairline. Scalp micropigmentation (SMP) blends hair with the scalp, reducing the visibility of thinning and enhancing overall density."}
                    text3={"Learn about SMP for Alopecia"}
                    image1={SMP_FOR_WOMEN_CARD3_IMAGE_1}
                    image2={SMP_FOR_WOMEN_CARD3_IMAGE_2}
                    onClickAction={() => {
                    }}
                    addBottomSpace={true}
                ></TreatmentCard>
                {/* <TreatmentCard
                    text1={"SMP for scars"}
                    text2={"Conceal scars and imperfections with our innovative SMP techniques, seamlessly blending them with your natural hairline. Restore both appearance and confidence with our personalized solutions."}
                    text3={"Explore SMP for Scars"}
                    onClickAction={() => {
                    }}
                ></TreatmentCard> */}
            </div>
        </div>
    );
}

function TreatmentCard({ text1, text2, text3, image1, image2, onClickAction, addBottomSpace = false }) {
    return (
        <div className='treatment-for-women-card-component-container' onClick={onClickAction}>
            <div className='treatment-for-women-card-component-in'>
                <div className='treatment-for-women-card-component-images'>
                    <div className='treatment-for-women-card-component-image'>
                        <img src={image1} height={"100%"} style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }}></img>
                    </div>
                    <div className='treatment-for-women-card-component-image'>
                        <img src={image2} height={"100%"}></img>
                    </div>
                </div>
                <div className='treatment-for-women-card-component-content-area'>
                    <div className='treatment-for-women-card-component-text0'>
                        {/* SMP for men */}
                        {text1}
                    </div>
                    <div className='treatment-for-women-card-component-text1'>
                        {/* "I don't think about my hair anymore. I get dressed, get out of bed, and go to work, that's it. It looks so natural and so real! I just wish I had gotten it done sooner." */}
                        {text2}
                    </div>

                    {addBottomSpace && (<div style={{
                        height: "20px"
                    }}></div>)}
                    {/* <div className='treatment-for-women-card-component-text2'>
                        {text3}
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default TreatmentsForWomen;
