import React, { useState } from 'react';
import '../../styles/HomePage/About.css';
import { ChevronUp, ChevronDown } from 'lucide-react';
import { FOUNDER_IMAGE, ABOUT_TEXT, ABOUT_TITLE, ABOUT_BUTTON_TEXT } from '../../constants';


function About() {
    return (
        <div>
            <AboutMainContainerDesktop></AboutMainContainerDesktop>
            <AboutMainContainerTablet></AboutMainContainerTablet>
            <AboutMainContainerMobile></AboutMainContainerMobile>
        </div>
    );
}

function AboutMainContainerDesktop() {
    return (
        <div className='about-main-container-desktop'>
            <div className='about-row'>
                <div className='about-left-side'>
                    <div className='about-left-heading'>
                        {ABOUT_TITLE}
                    </div>
                    <div className='about-left-desc'>
                        {ABOUT_TEXT}
                    </div>
                    <button className='view-all-about-button' onClick={() => {
                        window.scrollTo(0, 3800);
                    }}>
                        {ABOUT_BUTTON_TEXT}
                    </button>
                </div>
                <div style={{
                    backgroundColor: 'black',
                    height: 'fit-content',
                    display: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '0px 70px 0px 70px',
                    margin: '0px 0px 0px 80px',
                }}>
                    <div className='founder-image-container'>
                        <img src={FOUNDER_IMAGE}></img>
                    </div>
                </div>
            </div>
        </div>
    );
}

function AboutMainContainerTablet() {
    return (
        <div className='about-main-container-tablet'>
            <div className='about-row'>
                <div className='about-left-side'>
                    {/* <div className='about-left-about'>
                        FAQ
                    </div> */}
                    <div className='about-left-heading'>
                        {ABOUT_TITLE}
                    </div>
                    <div className='about-left-desc'>
                        {ABOUT_TEXT}
                    </div>
                    <button className='view-all-about-button' onClick={() => {
                        window.scrollTo(0, 4900);
                    }}>
                        {ABOUT_BUTTON_TEXT}
                    </button>
                </div>
                <div>
                    <img className='founder-image-container' src={FOUNDER_IMAGE}></img>
                </div>
            </div>
        </div>
    );
}

function AboutMainContainerMobile() {
    return (
        <div className='about-main-container-mobile'>
            <div className='about-row'>
                <div className='about-left-side'>
                    <div className='about-left-heading'>
                        {ABOUT_TITLE}
                    </div>
                    <div className='about-left-desc'>
                        {ABOUT_TEXT}
                    </div>
                    <div>
                        <img className='founder-image-container' src={FOUNDER_IMAGE}></img>
                    </div>
                    <button className='view-all-about-button' onClick={() => {
                        window.scrollTo(0, 6620);
                    }}>
                        {ABOUT_BUTTON_TEXT}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default About;