import React from 'react';
import '../../styles/HomePage/Footer.css';
import { FaFacebook, FaInstagram, FaYoutube, FaTiktok, FaWhatsapp } from 'react-icons/fa';
import { CONTACT_EMAIL, CONTACT_PHONE, FOOTER_BUTTON_TEXT, FOOTER_EMAIL_FIELD_HEADING, FOOTER_HASHTAG, FOOTER_LEARN_HEADING, FOOTER_LEARN_OPTION_1, FOOTER_LEARN_OPTION_2, FOOTER_LEARN_OPTION_3, FOOTER_LEARN_OPTION_4, FOOTER_LEARN_OPTION_5, FOOTER_LEARN_OPTION_6, FOOTER_LOCATIONS_HEADING, FOOTER_LOCATIONS_OPTION_1, FOOTER_LOCATIONS_OPTION_2, FOOTER_LOCATIONS_OPTION_3, FOOTER_LOCATIONS_OPTION_4, FOOTER_LOCATIONS_OPTION_5, FOOTER_LOCATIONS_OPTION_6, FOOTER_LOCATIONS_OPTION_7, FOOTER_NEWSLETTER_HEADING, FOOTER_TEXT_1, FOOTER_TEXT_2, FOOTER_TEXT_3, FOOTER_TREATMENTS_HEADING, FOOTER_TREATMENTS_OPTION_1, FOOTER_TREATMENTS_OPTION_2, FOOTER_TREATMENTS_OPTION_3, FOOTER_TREATMENTS_OPTION_4, FOOTER_TREATMENTS_OPTION_5, FOOTER_TREATMENTS_OPTION_6, FOOTER_TREATMENTS_OPTION_7, CONTACT_LINK_FACEBOOK, CONTACT_LINK_INSTAGRAM, CONTACT_LINK_YOUTUBE, CONTACT_LINK_WHATSAPP } from '../../constants';
import { useNavigate, useLocation } from "react-router-dom";
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useSelector, useDispatch } from 'react-redux';
import { selectEmail, setEmail } from '../../state/slices';

function Footer() {
    return (
        <div>
            <DesktopFooter></DesktopFooter>
            <TabletFooter></TabletFooter>
            <MobileFooter></MobileFooter>
        </div>
    );
}

function DesktopFooter() {
    const navigate = useNavigate();
    const location = useLocation();
    const { width } = useWindowDimensions();
    const email = useSelector(selectEmail);
    const dispatch = useDispatch();
    // Remove all white spaces from the phone number
    const cleanedPhoneNumber = CONTACT_PHONE.replace(/\s/g, '');
    return (
        <div className='desktop-footer-container'>
            <div className='footer'>
                <div className='footer-email-and-social-row'>
                    <div className='footer-email-field-column'>
                        {/* <div className='footer-newsletter-text'>{FOOTER_NEWSLETTER_HEADING}</div> */}
                        <div className='footer-enter-email-text'>
                            Shop No 2, Baage Fatima Building, Mahim West, Mumbai 400016</div>
                        {/* <input
                            type="text"
                            className='footer-email-textfield'
                            placeholder="Email"
                            id="email"
                            name="email"
                            value={email}
                            onChange={(event) => {
                                dispatch(setEmail(event.target.value))
                            }}
                        /> */}
                    </div>
                    <div className='footer-social-field-column'>
                        <div className='footer-firm-name-hashtag'>
                            {FOOTER_HASHTAG}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0px 0px 0px' }}>
                            <FaFacebook size="28px" className='footer-facebook-icon' onClick={() => {
                                console.log('Facebook');
                                window.open(CONTACT_LINK_FACEBOOK, '_blank', 'noopener,noreferrer');
                            }} />
                            <FaInstagram size="28px" className='footer-instagram-icon' onClick={() => {
                                console.log('Instagram');
                                window.open(CONTACT_LINK_INSTAGRAM, '_blank', 'noopener,noreferrer');
                            }} />
                            {/* <FaYoutube size="28px" className='footer-youtube-icon' onClick={() => {
                                console.log('Youtube');
                                window.open(CONTACT_LINK_YOUTUBE, '_blank', 'noopener,noreferrer');
                            }} /> */}
                            {/* <FaTiktok size="28px" className='footer-tiktok-icon' /> */}
                            <FaWhatsapp size="28px" className='footer-whatsapp-icon' onClick={() => {
                                console.log('WhatsApp');
                                window.open(CONTACT_LINK_WHATSAPP, '_blank', 'noopener,noreferrer');
                            }} />
                        </div>
                    </div>
                </div>
                <button className="transparent-button-footer-address" onClick={() => {
                    const address = 'Micro Inc. SMP, Shop No 2, Baage Fatima Building, Mahim West, Mumbai 400016';
                    const googleMapsUrl = `https://maps.google.com/maps?q=${encodeURIComponent(address)}`;
                    window.open(googleMapsUrl, '_blank', 'noopener,noreferrer');
                }}>
                    Get Directions
                </button>
                <div className='footer-contact-and-options-row'>
                    <div className='footer-contact-column'>
                        <a className='footer-contact-email-text' href={`mailto:${CONTACT_EMAIL}`}>
                            {CONTACT_EMAIL}
                        </a>
                        <a className='footer-contact-phone-text' href={`tel:${cleanedPhoneNumber}`}>
                            {CONTACT_PHONE}
                        </a>
                        <button className="transparent-button-footer" onClick={() => {
                            if (location.pathname === '/smpformen/') {
                                if (width >= 1025) {
                                    window.scrollTo(0, 2520);
                                } else {
                                    window.scrollTo(0, 4900);
                                }
                                return;
                            }
                            if (location.pathname === '/smpforwomen/') {
                                if (width >= 1025) {
                                    window.scrollTo(0, 2750);
                                } else {
                                    window.scrollTo(0, 4900);
                                }
                                return;
                            }
                            if (location.pathname === '/smpforalopecia/') {
                                if (width >= 1025) {
                                    window.scrollTo(0, 2100);
                                } else {
                                    window.scrollTo(0, 2400);
                                }
                                return;
                            }
                            if (location.pathname === '/smpforscars/') {
                                if (width >= 1025) {
                                    window.scrollTo(0, 1950);
                                } else {
                                    window.scrollTo(0, 2550);
                                }
                                return;
                            }
                            if (width >= 1025) {
                                window.scrollTo(0, 3800);
                            } else {
                                window.scrollTo(0, 4900);
                            }
                        }}>
                            {FOOTER_BUTTON_TEXT}
                        </button>
                    </div>
                    <div className='footer-options-row'>
                        <div className='footer-options-row-column'>
                            <div className='options-row-column-heading'>{FOOTER_TREATMENTS_HEADING}</div>
                            <div className='options-row-column-option' onClick={() => {
                                if (location.pathname === '/smpformen/') {
                                    return;
                                }
                                window.scrollTo(0, 0);
                                navigate("/smpformen/");
                            }}>{FOOTER_TREATMENTS_OPTION_1}</div>
                            <div className='options-row-column-option' onClick={() => {
                                if (location.pathname === '/smpforwomen/') {
                                    return;
                                }
                                window.scrollTo(0, 0);
                                navigate("/smpforwomen/");
                            }}>{FOOTER_TREATMENTS_OPTION_2}</div>
                            <div className='options-row-column-option' onClick={() => {
                                if (location.pathname === '/smpforalopecia/') {
                                    return;
                                }
                                window.scrollTo(0, 0);
                                navigate("/smpforalopecia/");
                            }}>{FOOTER_TREATMENTS_OPTION_3}</div>
                            <div className='options-row-column-option' onClick={() => {
                                if (location.pathname === '/smpforscars/') {
                                    return;
                                }
                                window.scrollTo(0, 0);
                                navigate("/smpforscars/");
                            }}>{FOOTER_TREATMENTS_OPTION_4}</div>
                            {/* <div className='options-row-column-option'>{FOOTER_TREATMENTS_OPTION_5}</div>
                            <div className='options-row-column-option'>{FOOTER_TREATMENTS_OPTION_6}</div>
                            <div className='options-row-column-option'>{FOOTER_TREATMENTS_OPTION_7}</div> */}
                        </div>
                        <div className='footer-options-row-column'>
                            <div className='options-row-column-heading'>{FOOTER_LEARN_HEADING}</div>
                            <div className='options-row-column-option' onClick={() => {
                                if (location.pathname === '/faq/') {
                                    return;
                                }
                                window.scrollTo(0, 0);
                                navigate("/faq/");
                            }}>{FOOTER_LEARN_OPTION_1}</div>
                            <div className='options-row-column-option' onClick={() => {
                                if (location.pathname === '/blog/') {
                                    window.scrollTo(0, 0);
                                    return;
                                }
                                window.scrollTo(0, 0);
                                navigate('/blog/');
                            }}>{FOOTER_LEARN_OPTION_2}</div>
                            {/* <div className='options-row-column-option'>{FOOTER_LEARN_OPTION_3}</div>
                            <div className='options-row-column-option'>{FOOTER_LEARN_OPTION_4}</div>
                            <div className='options-row-column-option'>{FOOTER_LEARN_OPTION_5}</div>
                            <div className='options-row-column-option'>{FOOTER_LEARN_OPTION_6}</div> */}
                        </div>
                        {/* <div className='footer-options-row-column'>
                            <div className='options-row-column-heading'>{FOOTER_LOCATIONS_HEADING}</div>
                            <div className='options-row-column-option'>{FOOTER_LOCATIONS_OPTION_1}</div>
                            <div className='options-row-column-option'>{FOOTER_LOCATIONS_OPTION_2}</div>
                            <div className='options-row-column-option'>{FOOTER_LOCATIONS_OPTION_3}</div>
                            <div className='options-row-column-option'>{FOOTER_LOCATIONS_OPTION_4}</div>
                            <div className='options-row-column-option'>{FOOTER_LOCATIONS_OPTION_5}</div>
                            <div className='options-row-column-option'>{FOOTER_LOCATIONS_OPTION_6}</div>
                            <div className='options-row-column-option'>{FOOTER_LOCATIONS_OPTION_7}</div>
                        </div> */}
                    </div>
                </div>
                <div className='copyright-and-terms-row'>
                    <div className='copyright-text'>
                        {FOOTER_TEXT_1}
                    </div>
                    <div className='terms-row'>
                        <div className='terms-text' onClick={() => {
                            navigate("/privacypolicy/");
                        }}>
                            {FOOTER_TEXT_2}
                        </div>
                        <div className='terms-text' onClick={() => {
                            navigate("/termsandconditions/");
                        }}>
                            {FOOTER_TEXT_3}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function TabletFooter() {
    const navigate = useNavigate();
    const location = useLocation();
    const email = useSelector(selectEmail);
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();
    // Remove all white spaces from the phone number
    const cleanedPhoneNumber = CONTACT_PHONE.replace(/\s/g, '');
    return (
        <div className='tablet-footer-container'>
            <div className='footer'>
                <div className='footer-email-and-social-row'>
                    <div className='footer-email-field-column'>
                        {/* <div className='footer-newsletter-text'>{FOOTER_NEWSLETTER_HEADING}</div> */}
                        <div className='footer-enter-email-text'>
                            Shop No 2, Baage Fatima Building, Mahim West, Mumbai 400016
                        </div>
                        {/* <input
                            type="text"
                            className='footer-email-textfield'
                            placeholder="Email"
                            id="email"
                            name="email"
                            value={email}
                            onChange={(event) => {
                                dispatch(setEmail(event.target.value))
                            }}
                        /> */}
                        <div style={{ height: '40px' }}></div>
                    </div>
                </div>
                <button className="transparent-button-footer-address" onClick={() => {
                    const address = 'Micro Inc. SMP, Shop No 2, Baage Fatima Building, Mahim West, Mumbai 400016';
                    const googleMapsUrl = `https://maps.google.com/maps?q=${encodeURIComponent(address)}`;
                    window.open(googleMapsUrl, '_blank', 'noopener,noreferrer');
                }}>
                    Get Directions
                </button>
                <div className='footer-contact-and-options-column'>
                    <div className='footer-options-row'>
                        <div className='footer-options-row-column'>
                            <div className='options-row-column-heading'>{FOOTER_TREATMENTS_HEADING}</div>
                            <div className='options-row-column-option' onClick={() => {
                                if (location.pathname === '/smpformen/') {
                                    return;
                                }
                                window.scrollTo(0, 0);
                                navigate("/smpformen/");
                            }}>{FOOTER_TREATMENTS_OPTION_1}</div>
                            <div className='options-row-column-option' onClick={() => {
                                if (location.pathname === '/smpforwomen/') {
                                    return;
                                }
                                window.scrollTo(0, 0);
                                navigate("/smpforwomen/");
                            }}>{FOOTER_TREATMENTS_OPTION_2}</div>
                            <div className='options-row-column-option' onClick={() => {
                                if (location.pathname === '/smpforalopecia/') {
                                    return;
                                }
                                window.scrollTo(0, 0);
                                navigate("/smpforalopecia/");
                            }}>{FOOTER_TREATMENTS_OPTION_3}</div>
                            <div className='options-row-column-option' onClick={() => {
                                if (location.pathname === '/smpforscars/') {
                                    return;
                                }
                                window.scrollTo(0, 0);
                                navigate("/smpforscars/");
                            }}>{FOOTER_TREATMENTS_OPTION_4}</div>
                            {/* <div className='options-row-column-option'>{FOOTER_TREATMENTS_OPTION_5}</div>
                            <div className='options-row-column-option'>{FOOTER_TREATMENTS_OPTION_6}</div>
                            <div className='options-row-column-option'>{FOOTER_TREATMENTS_OPTION_7}</div> */}
                        </div>
                        <div className='footer-options-row-column'>
                            <div className='options-row-column-heading'>{FOOTER_LEARN_HEADING}</div>
                            <div className='options-row-column-option' onClick={() => {
                                if (location.pathname === '/faq/') {
                                    return;
                                }
                                window.scrollTo(0, 0);
                                navigate("/faq/");
                            }}>{FOOTER_LEARN_OPTION_1}</div>
                            <div className='options-row-column-option' onClick={() => {
                                if (location.pathname === '/blog/') {
                                    return;
                                }
                                window.scrollTo(0, 0);
                                navigate('/blog/');
                            }}>{FOOTER_LEARN_OPTION_2}</div>
                            {/* <div className='options-row-column-option' onClick={() => {
                                // navigate("/faq/");
                            }}>{FOOTER_LEARN_OPTION_2}</div> */}
                            {/* <div className='options-row-column-option'>{FOOTER_LEARN_OPTION_3}</div>
                            <div className='options-row-column-option'>{FOOTER_LEARN_OPTION_4}</div>
                            <div className='options-row-column-option'>{FOOTER_LEARN_OPTION_5}</div>
                            <div className='options-row-column-option'>{FOOTER_LEARN_OPTION_6}</div> */}
                        </div>
                        {/* <div className='footer-options-row-column'>
                            <div className='options-row-column-heading'>{FOOTER_LOCATIONS_HEADING}</div>
                            <div className='options-row-column-option'>{FOOTER_LOCATIONS_OPTION_1}</div>
                            <div className='options-row-column-option'>{FOOTER_LOCATIONS_OPTION_2}</div>
                            <div className='options-row-column-option'>{FOOTER_LOCATIONS_OPTION_3}</div>
                            <div className='options-row-column-option'>{FOOTER_LOCATIONS_OPTION_4}</div>
                            <div className='options-row-column-option'>{FOOTER_LOCATIONS_OPTION_5}</div>
                            <div className='options-row-column-option'>{FOOTER_LOCATIONS_OPTION_6}</div>
                            <div className='options-row-column-option'>{FOOTER_LOCATIONS_OPTION_7}</div>
                        </div> */}
                    </div>
                    <div className='footer-contact-column'>
                        <a className='footer-contact-email-text' href={`mailto:${CONTACT_EMAIL}`}>
                            {CONTACT_EMAIL}
                        </a>
                        <a className='footer-contact-phone-text' href={`tel:${cleanedPhoneNumber}`}>
                            {CONTACT_PHONE}
                        </a>
                        <button className="transparent-button" onClick={() => {
                            if (location.pathname === '/smpformen/') {
                                if (width >= 1025) {
                                    window.scrollTo(0, 2520);
                                } else {
                                    window.scrollTo(0, 3200);
                                }
                                return;
                            }
                            if (location.pathname === '/smpforwomen/') {
                                if (width >= 1025) {
                                    window.scrollTo(0, 2750);
                                } else {
                                    window.scrollTo(0, 2630);
                                }
                                return;
                            }
                            if (location.pathname === '/smpforscars/') {
                                if (width >= 1025) {
                                    window.scrollTo(0, 1950);
                                } else {
                                    window.scrollTo(0, 2000);
                                }
                                return;
                            }
                            if (width >= 1025) {
                                window.scrollTo(0, 3800);
                            } else {
                                window.scrollTo(0, 2300);
                            }
                        }}>
                            {FOOTER_BUTTON_TEXT}
                        </button>
                    </div>

                </div>
                <div className='copyright-and-terms-column'>
                    <div className='footer-social-field-column'>
                        {/* <div className='footer-firm-name-hashtag'>
                            {FOOTER_HASHTAG}
                        </div> */}
                        <div style={{ display: 'flex', alignItems: 'center', margin: '80px 0px 0px 0px' }}>
                            <FaFacebook size="28px" className='footer-facebook-icon' onClick={() => {
                                console.log('Facebook');
                                window.open(CONTACT_LINK_FACEBOOK, '_blank', 'noopener,noreferrer');
                            }} />
                            <FaInstagram size="28px" className='footer-instagram-icon' onClick={() => {
                                console.log('Instagram');
                                window.open(CONTACT_LINK_INSTAGRAM, '_blank', 'noopener,noreferrer');
                            }} />
                            {/* <FaYoutube size="28px" className='footer-youtube-icon' onClick={() => {
                                console.log('Youtube');
                                window.open(CONTACT_LINK_YOUTUBE, '_blank', 'noopener,noreferrer');
                            }} /> */}
                            {/* <FaTiktok size="28px" className='footer-tiktok-icon' /> */}
                            <FaWhatsapp size="28px" className='footer-whatsapp-icon' onClick={() => {
                                console.log('WhatsApp');
                                window.open(CONTACT_LINK_WHATSAPP, '_blank', 'noopener,noreferrer');
                            }} />
                        </div>
                    </div>
                    <div className='terms-text' onClick={() => {
                        navigate("/privacypolicy/");
                    }}>
                        {FOOTER_TEXT_2}
                    </div>
                    <div className='terms-text' onClick={() => {
                        navigate("/termsandconditions/");
                    }}>
                        {FOOTER_TEXT_3}
                    </div>
                    <div className='copyright-text'>
                        {FOOTER_TEXT_1}
                    </div>
                </div>
            </div>
        </div>
    );
}

function MobileFooter() {
    const navigate = useNavigate();
    const location = useLocation();
    const { width } = useWindowDimensions();
    const email = useSelector(selectEmail);
    const dispatch = useDispatch();
    // Remove all white spaces from the phone number
    const cleanedPhoneNumber = CONTACT_PHONE.replace(/\s/g, '');
    return (
        <div className='mobile-footer-container'>
            <div className='footer'>
                <div className='footer-social-field-column'>
                    <div style={{ display: 'flex', alignItems: 'center', margin: '0px 0px 40px 0px' }}>
                        <FaFacebook size="28px" className='footer-facebook-icon' onClick={() => {
                            console.log('Facebook');
                            window.open(CONTACT_LINK_FACEBOOK, '_blank', 'noopener,noreferrer');
                        }} />
                        <FaInstagram size="28px" className='footer-instagram-icon' onClick={() => {
                            console.log('Instagram');
                            window.open(CONTACT_LINK_INSTAGRAM, '_blank', 'noopener,noreferrer');
                        }} />
                        <FaWhatsapp size="28px" className='footer-whatsapp-icon' onClick={() => {
                            console.log('WhatsApp');
                            window.open(CONTACT_LINK_WHATSAPP, '_blank', 'noopener,noreferrer');
                        }} />
                    </div>
                </div>
                <div className='footer-contact-column'>
                    <a className='footer-contact-email-text' href={`mailto:${CONTACT_EMAIL}`}>
                        {CONTACT_EMAIL}
                    </a>
                    <a className='footer-contact-phone-text' href={`tel:${cleanedPhoneNumber}`}>
                        {CONTACT_PHONE}
                    </a>
                    <div style={{ height: "30px" }}></div>
                </div>
                <div className='footer-email-and-social-row'>
                    <div className='footer-email-field-column'>
                        {/* <div className='footer-newsletter-text'>{FOOTER_NEWSLETTER_HEADING}</div> */}
                        <div className='footer-enter-email-text'>
                            Shop No 2, Baage Fatima Building, Mahim West, Mumbai 400016
                        </div>
                        {/* <input
                            type="text"
                            className='footer-email-textfield'
                            placeholder="Email"
                            id="email"
                            name="email"
                            value={email}
                            onChange={(event) => {
                                dispatch(setEmail(event.target.value))
                            }}
                        /> */}
                        <div style={{ height: '40px' }}></div>
                    </div>
                </div>
                {/* <div style={{ height: "30px" }}></div> */}
                <button className="transparent-button-address" onClick={() => {
                    const address = 'Micro Inc. SMP, Shop No 2, Baage Fatima Building, Mahim West, Mumbai 400016';
                    const googleMapsUrl = `https://maps.google.com/maps?q=${encodeURIComponent(address)}`;
                    window.open(googleMapsUrl, '_blank', 'noopener,noreferrer');
                }}>
                    Get Directions
                </button>
                <div className='footer-contact-and-options-column'>
                    <div className='footer-options-row'>
                        <div className='footer-options-row-column'>
                            <div className='options-row-column-heading'>{FOOTER_TREATMENTS_HEADING}</div>
                            <div className='options-row-column-option' onClick={() => {
                                if (location.pathname === '/smpformen/') {
                                    return;
                                }
                                window.scrollTo(0, 0);
                                navigate("/smpformen/");
                            }}>{FOOTER_TREATMENTS_OPTION_1}</div>
                            <div className='options-row-column-option' onClick={() => {
                                if (location.pathname === '/smpforwomen/') {
                                    return;
                                }
                                window.scrollTo(0, 0);
                                navigate("/smpforwomen/");
                            }}>{FOOTER_TREATMENTS_OPTION_2}</div>
                            <div className='options-row-column-option' onClick={() => {
                                if (location.pathname === '/smpforalopecia/') {
                                    return;
                                }
                                window.scrollTo(0, 0);
                                navigate("/smpforalopecia/");
                            }}>{FOOTER_TREATMENTS_OPTION_3}</div>
                            <div className='options-row-column-option' onClick={() => {
                                if (location.pathname === '/smpforscars/') {
                                    return;
                                }
                                window.scrollTo(0, 0);
                                navigate("/smpforscars/");
                            }}>{FOOTER_TREATMENTS_OPTION_4}</div>
                            {/* <div className='options-row-column-option'>Female Hair Loss</div>
                            <div className='options-row-column-option'>Reviews</div>
                            <div className='options-row-column-option'>Payment Plans</div> */}
                        </div>
                        <div className='footer-options-row-column'>
                            <div className='options-row-column-heading'>{FOOTER_LEARN_HEADING}</div>
                            <div className='options-row-column-option' onClick={() => {
                                if (location.pathname === '/faq/') {
                                    return;
                                }
                                window.scrollTo(0, 0);
                                navigate("/faq/");
                            }}>{FOOTER_LEARN_OPTION_1}</div>
                            <div className='options-row-column-option' onClick={() => {
                                if (location.pathname === '/blog/') {
                                    return;
                                }
                                window.scrollTo(0, 0);
                                navigate('/blog/');
                            }}>{FOOTER_LEARN_OPTION_2}</div>
                            {/* <div className='options-row-column-option' onClick={() => {
                                // navigate("/faq/");
                            }}>{FOOTER_LEARN_OPTION_2}</div> */}
                            {/* <div className='options-row-column-option'>FAQ</div>
                            <div className='options-row-column-option'>Blog</div>
                            <div className='options-row-column-option'>Press</div>
                            <div className='options-row-column-option'>Contact</div> */}
                        </div>
                        {/* <div className='footer-options-row-column'>
                            <div className='options-row-column-heading'>{FOOTER_LOCATIONS_HEADING}</div>
                            <div className='options-row-column-option'>New York</div>
                            <div className='options-row-column-option'>Los Angeles</div>
                            <div className='options-row-column-option'>Scar camouflage</div>
                            <div className='options-row-column-option'>Houston</div>
                            <div className='options-row-column-option'>Chicago</div>
                            <div className='options-row-column-option'>Reviews</div>
                            <div className='options-row-column-option'>South Florida</div>
                        </div> */}
                    </div>
                    {/* <div className='footer-contact-column'>
                        <a className='footer-contact-email-text' href={`mailto:${CONTACT_EMAIL}`}>
                            {CONTACT_EMAIL}
                        </a>
                        <a className='footer-contact-phone-text' href={`tel:${cleanedPhoneNumber}`}>
                            {CONTACT_PHONE}
                        </a>
                        <div style={{ height: "30px" }}></div>
                    </div> */}
                </div>
                <div className='copyright-and-terms-column'>
                    {/* <div className='footer-social-field-column'>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '50px 0px 0px 0px' }}>
                            <FaFacebook size="28px" className='footer-facebook-icon' onClick={() => {
                                console.log('Facebook');
                                window.open(CONTACT_LINK_FACEBOOK, '_blank', 'noopener,noreferrer');
                            }} />
                            <FaInstagram size="28px" className='footer-instagram-icon' onClick={() => {
                                console.log('Instagram');
                                window.open(CONTACT_LINK_INSTAGRAM, '_blank', 'noopener,noreferrer');
                            }} />
                            <FaWhatsapp size="28px" className='footer-whatsapp-icon' onClick={() => {
                                console.log('WhatsApp');
                                window.open(CONTACT_LINK_WHATSAPP, '_blank', 'noopener,noreferrer');
                            }} />
                        </div>
                    </div> */}
                    <div className='terms-text' onClick={() => {
                        navigate("/privacypolicy/");
                    }}>
                        {FOOTER_TEXT_2}
                    </div>
                    <div className='terms-text' onClick={() => {
                        navigate("/termsandconditions/");
                    }}>
                        {FOOTER_TEXT_3}
                    </div>
                    <div className='copyright-text'>
                        {FOOTER_TEXT_1}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;